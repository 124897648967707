import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { homepageValuationFormSchema } from '@propertypal/shared/src/constants/instantValuation';
import { MY_DRAWN_AREA, MY_TRAVEL_TIME_AREA } from '@propertypal/shared/src/constants/search';
import useLoqate, { useLoqateWithFormik } from '@propertypal/shared/src/hooks/useLoqate';
import { RecentSearch } from '@propertypal/shared/src/hooks/useRecentSearches';
import useScrollPosition from '@propertypal/shared/src/hooks/useScrollPosition';
import useSearchState from '@propertypal/shared/src/hooks/useSearchState';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { Filters } from '@propertypal/shared/src/utils/search/getNlpFilters';
import { Suggestion } from '@propertypal/shared/src/utils/search/groupTokensByType';
import Button from '@propertypal/web-ui/src/buttons/Button';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import SearchInput from '@propertypal/web-ui/src/inputs/SearchInput';
import TextInput from '@propertypal/web-ui/src/inputs/TextInput';
import { useFormik } from 'formik';
import 'intersection-observer';
import { useRouter } from 'next/router';
import React, { CSSProperties, FormEvent, FunctionComponent, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import getCurrentLocation from '../../services/location';
import {
  Container,
  SearchButtons,
  SearchForm,
  SearchInputContainer,
  TabContainer,
  Tab,
  TabArrow,
  TabRail,
} from './HomeSearchBar.style';
import SearchPreviewModal from './SearchPreviewModal';

type Active = 'Property' | 'Instant' | 'Agent';

interface Props {
  containerStyle?: CSSProperties;
  submitText: string;
  onSubmit: (baseUrl: string, searchText: string, filters: Filters) => void;
  extraNlpParams?: string;
  placeholder?: string;
  showInstantValuation?: boolean;
  showTravelPreview?: boolean;
  recentSearches?: RecentSearch[];
  showSaleTypeOptions?: boolean;
  removeRecentSearch?: (search: RecentSearch) => void;
}

const HomeSearchBar: FunctionComponent<Props> = (props) => {
  const { handleChangeText, nlpData, searchText, getLocation, fetchingLocation } = useSearchState(props.extraNlpParams);
  const [active, setActive] = useState<Active>('Property');
  const [showSearchPreview, setShowSearchPreview] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { value, handleValueChange, options, handleOptionClick, postcode, loqateId } = useLoqate();
  const router = useRouter();
  const theme = useTheme();
  const tabRail = useRef<HTMLDivElement | null>(null);
  const tabContainer = useRef<HTMLDivElement | null>(null);

  const formik = useFormik({
    initialValues: {
      loqateId: '',
      postcode: '',
    },
    validationSchema: homepageValuationFormSchema,
    onSubmit: (values) => {
      trackGaEvent('valuation_click', {
        link_location: 'Homepage Form',
      });

      // redirect to instant valuation, passing all of the necessary query params
      router.push({
        pathname: `/${active.toLowerCase()}-valuation`,
        query: {
          address: value,
          loqateId: values.loqateId,
          postcode: values.postcode,
        },
      });
    },
  });

  const handleTabClick = (tab: Active) => (event: any) => {
    setActive(tab);
    tabRail.current?.scrollTo({ left: event.target.offsetLeft - 8, behavior: 'smooth' });
  };

  const handleLocationClick = (position: 'suggestion' | 'inline') => {
    trackGaEvent('current_location_click', {
      page: 'HP',
      location: 'Search Box',
      position,
    });
    return getLocation(getCurrentLocation, true);
  };

  const handleTravelTimeClick = (position: 'suggestion' | 'inline') => {
    trackGaEvent('travel_time_click', {
      page: 'HP',
      location: 'Search Box',
      position,
    });
    setShowSearchPreview('travelTime');
  };

  const handleDrawSearchClick = (position: 'suggestion' | 'inline') => {
    trackGaEvent('draw_click', {
      page: 'HP',
      location: 'Search Box',
      position,
    });
    setShowSearchPreview('drawSearch');
  };

  const getLocationText = async () => {
    const position = await getLocation(getCurrentLocation, false);

    if (position.lat) {
      return position;
    }

    return {};
  };

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (active === 'Property') {
      const position = await getLocationText();

      props.onSubmit('', searchText, {
        ...position,
      });
    } else {
      formik.handleSubmit(e);
    }
  };

  const handleSecondarySubmit = (category: string, saleType: string) => async () => {
    const position = await getLocationText();

    props.onSubmit('', searchText, {
      category,
      saleType,
      ...position,
    });
  };

  const handleSuggestionClick = (token: Suggestion) => {
    if (token.url) {
      router.push(token.url);
    } else {
      handleChangeText(token.text);
    }
  };

  const handlePreviewSubmit = (polyIds: number[], category: string, saleType: string) => {
    props.onSubmit('/map', showSearchPreview === 'travelTime' ? MY_TRAVEL_TIME_AREA : MY_DRAWN_AREA, {
      category,
      saleType,
      polyIds,
    });
  };

  useLoqateWithFormik(formik, value, postcode, loqateId);

  useScrollPosition(tabContainer, tabRail);

  return (
    <Container style={props.containerStyle}>
      {props.showInstantValuation && (
        <TabContainer ref={tabContainer} className="scroll-start">
          <TabArrow
            className="arrow-left"
            direction="left"
            onClick={() => tabRail.current?.scrollTo({ left: 0, behavior: 'smooth' })}
          >
            <FontAwesomeIcon icon={faChevronLeft} color={theme.primary} style={{ fontSize: 20 }} />
          </TabArrow>

          <TabArrow
            className="arrow-right"
            direction="right"
            onClick={() => tabRail.current?.scrollTo({ left: 500, behavior: 'smooth' })}
          >
            <FontAwesomeIcon icon={faChevronRight} color={theme.primary} style={{ fontSize: 20 }} />
          </TabArrow>

          <TabRail ref={tabRail}>
            <Tab active={active === 'Property'} onClick={handleTabClick('Property')}>
              Find a Property
            </Tab>

            <Tab active={active === 'Instant'} onClick={handleTabClick('Instant')}>
              Instant Valuation
            </Tab>

            <Tab active={active === 'Agent'} onClick={handleTabClick('Agent')}>
              Agent Valuation
            </Tab>
          </TabRail>
        </TabContainer>
      )}

      <SearchForm action="" onSubmit={handleSubmit}>
        {active === 'Property' && (
          <>
            <SearchInputContainer>
              <SearchInput
                inputRef={inputRef}
                disableSavedSearches
                onChangeText={handleChangeText}
                loading={fetchingLocation}
                placeholder={props.placeholder}
                suggestionsOffset={props.showInstantValuation ? 115 : 80}
                suggestionsLeftOffset={6}
                value={searchText}
                nlpData={nlpData}
                onLocationClick={handleLocationClick}
                onSuggestionClick={handleSuggestionClick}
                onTravelTimeClick={props.showTravelPreview ? handleTravelTimeClick : undefined}
                onDrawSearchClick={props.showTravelPreview ? handleDrawSearchClick : undefined}
                isHomePage
                recentSearches={props.recentSearches}
                onRecentSearchClick={(s, f) => props.onSubmit('', s, f)}
                onRemoveRecentSearch={props.removeRecentSearch}
              />
            </SearchInputContainer>

            <SearchButtons multiButtons={props.showSaleTypeOptions}>
              <Button containerStyle={{ height: '54px' }} testID="forSaleButton" type="submit">
                {props.submitText}
              </Button>

              {props.showSaleTypeOptions && (
                <Button
                  containerStyle={{ height: '54px' }}
                  testID="forRentButton"
                  onClick={handleSecondarySubmit('residential', 'rent')}
                  type="button"
                >
                  For Rent
                </Button>
              )}

              {props.showSaleTypeOptions && (
                <Button
                  containerStyle={{ height: '54px' }}
                  testID="newHomesButton"
                  onClick={handleSecondarySubmit('newhomes', 'sale')}
                  type="button"
                >
                  New Homes
                </Button>
              )}
            </SearchButtons>
          </>
        )}

        {active !== 'Property' && (
          <>
            <SearchInputContainer>
              <TextInput
                rounded
                placeholder="Enter an address..."
                name="addressLookup"
                value={value}
                onValueChange={handleValueChange}
                autoCompleteOptions={options}
                onOptionClick={handleOptionClick}
                error={
                  ((formik.touched.postcode && formik.errors.postcode) ||
                    (formik.touched.loqateId && formik.errors.loqateId)) &&
                  'Please enter a valid NI address or postcode'
                }
                testID="addressLookup"
                icon={faSearch}
              />
            </SearchInputContainer>

            <SearchButtons>
              <Button containerStyle={{ height: '54px' }} type="submit">
                Get an {active} Valuation
              </Button>
            </SearchButtons>
          </>
        )}
      </SearchForm>

      {showSearchPreview && (
        <SearchPreviewModal
          show={showSearchPreview}
          onClose={() => setShowSearchPreview('')}
          onSubmit={handlePreviewSubmit}
          showSaleTypeOptions={props.showSaleTypeOptions}
        />
      )}
    </Container>
  );
};

export default HomeSearchBar;
